<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group">
        <el-button type="primary" @click="submit('stffForm')" size="small"
          >保存</el-button
        >
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
      <el-form
        ref="stffForm"
        :model="stffForm"
        :rules="rules"
        label-width="120px"
        size="mini"
        :show-message="false"
      >
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="员工编号" prop="stff_no" required>
              <el-input
                v-model="stffForm.stff_no"
                maxlength="10"
                show-word-limit
                placeholder="请填写员工编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="stff_name" required>
              <el-input
                v-model="stffForm.stff_name"
                maxlength="10"
                @input="
                  stffForm.stff_name = helper.keepChin(stffForm.stff_name)
                "
                show-word-limit
                placeholder="请填写员工姓名（中文）"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="公司抬头"
              prop="cptt_id"
              :rules="[{ required: true }]"
            >
              <el-select
                v-model="stffForm.cptt_id"
                placeholder="请选择公司抬头"
                clearable
                @visible-change="getAcctFctrs($event)"
                @change="selectCpttName"
              >
                <el-option
                  v-for="item in cpttGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="部门名称"
              prop="dept_id"
              :rules="[{ required: true }]"
            >
              <el-select
                v-model="stffForm.dept_id"
                placeholder="请选择部门"
                clearable
                @visible-change="getDeptV1($event)"
                @change="openTeam"
              >
                <el-option
                  v-for="item in deptGroupOpt"
                  :key="item.dept_id"
                  :label="item.dept_name"
                  :value="item.dept_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组名称"  prop="dept_team_id" :rules="[{ required: true }]">
              <el-select
                v-model="stffForm.dept_team_id"
                placeholder="请选择小组（部门未选时禁选）"
                clearable
                @visible-change="getTeamByDept($event)"
                :disabled="disabled"
              >
                <el-option
                  v-for="item in teamGroupOpt"
                  :key="item.dept_team_id"
                  :label="item.dept_team_name"
                  :value="item.dept_team_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工岗位" prop="stff_job">
              <el-input
                v-model="stffForm.stff_job"
                maxlength="10"
                placeholder="请填写岗位"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="上级领导" prop="stff_leader_name">
              <select-input
                @refreshTableData="refreshTableData"
                :selTableData="selTableData"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_leader_name"
                :disabled="disabled2"
                :rowSelect="rowSelect"
                :isShowCol="false"
                placeholder="请选择上级领导"
              >
              </select-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="入职时间" prop="stffEnterTime" required>
              <el-date-picker
                type="date"
                placeholder="请选择入职时间"
                v-model="stffForm.stffEnterTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="离职时间" prop="stffLeaveTime">
              <el-date-picker
                type="date"
                placeholder="请选择离职时间"
                v-model="stffForm.stffLeaveTime"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="员工司龄" prop="stff_seniority">
              <el-input
                v-model="stffForm.stff_seniority"
                placeholder="根据入职或者离职时间自动填充"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="开户银行" prop="stff_bank_name">
              <el-input
                v-model="stffForm.stff_bank_name"
                maxlength="30"
                show-word-limit
                placeholder="请填写开户银行"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="stff_bank_no">
              <el-input
                v-model="stffForm.stff_bank_no"
                maxlength="30"
                show-word-limit
                placeholder="请填写银行卡号"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="stff_identity">
              <el-input
                v-model="stffForm.stff_identity"
                maxlength="18"
                show-word-limit
                placeholder="请填写身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="员工性别" prop="stff_gender">
              <el-input
                v-model="stffForm.stff_gender"
                placeholder="根据身份信息自动填充"
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="聘用类型" prop="stff_labr_type">
              <el-input
                v-model="stffForm.stff_labr_type"
                placeholder="请填写聘用类型"
                 maxlength="30"
                 show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="员工电话" prop="stff_phone">
              <el-input
                v-model="stffForm.stff_phone"
                maxlength="30"
                show-word-limit
                @input="
                  stffForm.stff_phone = helper.purephone(stffForm.stff_phone)
                "
                placeholder="请填写电话"
              ></el-input>
            </el-form-item>

            <el-form-item label="当前住址" prop="stff_address">
              <el-input
                v-model="stffForm.stff_address"
                maxlength="30"
                show-word-limit
                placeholder="请填写员工现住址"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="stff_remark">
              <el-input
                type="textarea"
                :rows="2"
                v-model="stffForm.stff_remark"
                maxlength="100"
                show-word-limit
                placeholder="请填写备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="vg_mt_16">
          <el-col>
            <inputUser :isCookie="true" ref="userData"></inputUser>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { stffAPI } from "@api/modules/staff";
import { acctAPI } from "@api/modules/acct";
import { deptAPI } from "@api/modules/department";
import { imgeAPI } from "@api/modules/imge";
import { cpttAPI} from '@api/modules/comptitle'
import inputUser from "@/views/component/inputUser";
import selectInput from "@/views/component/selectInput";
import { staffTableHeader } from "@/views/component/tableHeader/staffTableHeader";
import options from "@/views/component/common/options";
import helper from "@assets/js/helper";
import { optnAPI } from "@api/modules/optn";
export default {
  name: "StffAdd.vue",
  components: {
    selectInput,
    inputUser,
  },
  data() {
    return {
      stffForm: {
        stff_no: "",
        stff_name: "",
        stff_leader: null,
        stff_leader_name: "",
        dept_id: null,
        dept_team_id: null,
        stffEnterTime: null,
        stffLeaveTime: null,
        stff_gender: "",
        stff_phone: "",
        stff_identity: "",
        stff_address: "",
        stff_job: "",
        stff_bank_no: "",
        stff_handbook: "",
        stff_bank_name: "",
        stff_remark: "",
        stff_labr_list: [],
        stff_hire_tap: "",
        cptt_id:null
      },
      key: 0,
      compTitle: "",
      disabled: true,
      disabled2: false,
      merryDisabled: false,
      selTableData: [],
      cpttGroupOpt: [],
      deptGroupOpt: [],
      teamGroupOpt: [],
      contGroupOpt: [], // 合同类型
      selectionsList: [],
      staffTableHeader: [],
      pickerOptions: this.beginDate(),
      rules: {},
      rowSelect: [],
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        cut: true,
        x: 200,
        y: 200,
      },
    };
  },
  created() {
    this.getSelectTable();
    this.staffTableHeader = staffTableHeader;
    this.contGroupOpt = options.labOpts;
  },
  watch: {
    "stffForm.dept_id": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.stffForm.dept_team_id = null;
        this.teamGroupOpt = [];
      }
    },
    "stffForm.stff_marry": function (newVal) {
      if (newVal === "未婚未育" || newVal === "已婚未育") {
        this.merryDisabled = true;
      } else {
        this.merryDisabled = false;
      }
    },
    stffForm: {
      deep: true,
      handler(val) {
        if (val.stffEnterTime) {
          if (val.stffLeaveTime) {
            this.stffForm.stff_seniority = this.helper.toSeniority(
              val.stffEnterTime / 1000,
              val.stffLeaveTime / 1000
            );
          } else {
            this.stffForm.stff_seniority = this.helper.toSeniority(
              val.stffEnterTime / 1000,
              0
            );
          }
        } else {
          this.stffForm.stff_seniority = null;
        }
      },
    },
    "stffForm.stff_identity": function (newVal) {
      this.stffForm.stff_gender = this.helper.mathUserInfo(newVal, 2);
    },
  },
  methods: {
    // 离职时间控制关闭选项
    beginDate() {
      let that = this;
      return {
        disabledDate(time) {
          return time.getTime() < that.stffForm.stffEnterTime;
        },
      };
    },
    // 刷新数据
    refreshTableData(){
      this.getSelectTable()
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1)
        .then((res) => {
          if (res.data.code === 0) {
            this.selTableData = res.data.data;
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm.stff_leader = val.stff_id;
      this.stffForm.stff_leader_name = val.stff_name;
    },

    // 成功上传
    handleAvatarSuccess(res) {
      this.stffForm.imge_id = res.data.imge_id;
      this.stffForm.imge_url = this.helper.picUrl(res.data.imge_url, "l");
    },
    // 提交表单
    submit(formName) {
      this.$confirm("确认提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log("error submit!");
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const stffFormBody = this.stffForm;
      stffFormBody.stff_enter_time = Number(
        new Date(this.stffForm.stffEnterTime).getTime() / 1000
      );
      stffFormBody.stff_leave_time = Number(
        new Date(this.stffForm.stffLeaveTime).getTime() / 1000
      );
      const stffFormList = this.stffForm.stff_labr_list;
      for (let i = 0; i < stffFormList.length; i++) {
        stffFormList[i].stff_labr_in_time = Number(
          new Date(stffFormList[i].stff_labr_in_time).getTime() / 1000
        );
        stffFormList[i].stff_labr_out_time = Number(
          new Date(stffFormList[i].stff_labr_out_time).getTime() / 1000
        );
      }
      post(stffAPI.addStff, stffFormBody)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.resetForm("stffForm");
            this.stffForm.stff_labr_list = [];
            this.stffForm.imge_id = null;
            this.jump("/stff_edit", {
              perm_id: this.$route.query.perm_id,
              form_id: res.data.data.form_id,
            });
          } else if (res.data.code === 7) {
            this.$message({
              type: "error",
              message: "员工编号重复",
            });
            stffFormBody.stff_enter_time = Number(
              new Date(this.stffForm.stffEnterTime).getTime() * 1000
            );
            stffFormBody.stff_leave_time = Number(
              new Date(this.stffForm.stffLeaveTime).getTime() * 1000
            );
            for (let i = 0; i < stffFormList.length; i++) {
              stffFormList[i].stff_labr_in_time = Number(
                new Date(stffFormList[i].stff_labr_in_time).getTime() * 1000
              );
              stffFormList[i].stff_labr_out_time = Number(
                new Date(stffFormList[i].stff_labr_out_time).getTime() * 1000
              );
            }
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
            stffFormBody.stff_enter_time = Number(
              new Date(this.stffForm.stffEnterTime).getTime() / 1000
            );
            stffFormBody.stff_leave_time = Number(
              new Date(this.stffForm.stffLeaveTime).getTime() / 1000
            );
            for (let i = 0; i < stffFormList.length; i++) {
              stffFormList[i].stff_labr_in_time = Number(
                new Date(stffFormList[i].stff_labr_in_time).getTime() * 1000
              );
              stffFormList[i].stff_labr_out_time = Number(
                new Date(stffFormList[i].stff_labr_out_time).getTime() * 1000
              );
            }
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm("确定取消新增?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.resetForm("stffForm");
          this.$router.go(-1);
          this.$message({
            type: "info",
            message: "已取消新增!",
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.stff_labr_list = [];
    },
    //添加
    // addRow() {
    //   let item = {
    //     stff_labr_cont: null,
    //     stff_labr_in_time: null,
    //     stff_labr_out_time: null,
    //     cptt_name: this.compTitle,
    //     key: this.key,
    //   };
    //   this.key = this.key + 1;
    //   this.stffForm.stff_labr_list.push(item);
    // },
    //
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //多选删除
    delLabr() {
      for (let i = 0; i < this.selectionsList.length; i++) {
        this.stffForm.stff_labr_list.forEach((item) => {
          if (item.key === this.selectionsList[i].key) {
            let currentIndex = this.stffForm.stff_labr_list.indexOf(item);
            this.stffForm.stff_labr_list.splice(currentIndex, 1);
          }
        });
      }
    },
    // 获取cpttId
    getAcctFctrs(flag) {
      if (flag === true && this.cpttGroupOpt.length === 0) {
        get(cpttAPI.get_my_cptts_v1)
          .then((res) => {
            console.log(res);
            if (res.data.code === 0) {
              this.cpttGroupOpt = res.data.data;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1)
          .then((res) => {
            if (res.data.code === 0) {
              this.deptGroupOpt = res.data.data;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取deptTeamId
    getTeamByDept(flag) {
      if (flag === true && this.teamGroupOpt.length === 0) {
        get(deptAPI.getTeamsByDeptId, { dept_id: this.stffForm.dept_id })
          .then((res) => {
            if (res.data.code === 0) {
              this.teamGroupOpt = res.data.data;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 获取公司的抬头
    selectCpttName(params) {
      const parObj = this.cpttGroupOpt.find((item) => {
        return item.cptt_id === params;
      });
      this.compTitle = parObj.cptt_name;
    },
    //开启选择小组
    openTeam(val) {
      if (val) {
        this.stffForm.dept_id = val;
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
</style>